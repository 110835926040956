import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Moment from "react-moment"
import Layout from "../../components/layout"
import ArticlesComponent from "../../components/blog/articles"
import Categories from "../../components/blog/categories"

export const query = graphql`
query ArticleCategory($slug: String!) {
  articles: allStrapiArticles(filter: {categories: {elemMatch: {slug: {eq: $slug}}}}) {
    edges {
      node {
        strapiId
        slug
        title
        image {
          url
        }
        categories {
          slug
          title
        }
        author {
          name
        }
        date(formatString: "DD MMMM, YYYY")
      }
    }
  }
  category: strapiArticlesCategories(slug: {eq: $slug}) {
    title
  }
}
`;

const ArticleCategory = ({ data }) => {
  const articles = data.articles.edges;
  const category = data.category.title;
  const seo = {
    metaTitle: category,
    metaDescription: `All ${category} articles`,
  };

  return (
    <Layout seo={seo}>
      <div className="lg:mx-14 px-4 sm:px-6">
        <div className="flex flex-col gap-12 py-12">
          <div className>
            <p className="lg:text-6xl text-small font-semibold">How we think.</p>
          </div>
          <Categories />
        </div>
      </div>
      <div className="rounded-b-2xl lg:rounded-b-3xl" style={{boxShadow: '0px 0px 15px 3px #0000001A'}}>
        <div className="lg:mx-14 px-4 sm:px-6 py-12">
            <ArticlesComponent articles={articles} />
        </div>
      </div>
    </Layout>
  )
}

export default ArticleCategory